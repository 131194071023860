<template>
  <div class="flex items-center justify-center py-8 bg-gray-100" id="pricing">
    <div class="flex flex-col md:flex-row gap-8">
      <!-- Monthly Plan -->
      <div class="max-w-sm rounded-2xl border overflow-hidden bg-white relative">
        <div class="absolute top-0 left-0 bg-blue-500 text-white px-3 py-1 text-sm font-semibold rounded-br">
          Early Bird
        </div>
        <div class="px-6 py-8">
          <h2 class="text-center text-3xl font-semibold mb-4 serif">Monthly</h2>
          <p class="text-center text-gray-600 mb-6">Enjoy full access to all features with our monthly plan.</p>
          <div class="text-center mb-6">
            <div class="mb-1">
              <span class="text-gray-500 line-through">150 kr</span>
              <span class="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded ml-2"
                >Expires 31 Dec 2024</span
              >
            </div>
            <span class="text-4xl font-bold">100 kr</span>
            <span class="text-gray-600"> per month</span>
          </div>
          <div class="mb-6 space-y-2">
            <!-- Features List -->
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Speak with AI in English or Danish</div>
            </div>
            <!-- Repeat for other features -->
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Get answers in English or Danish</div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Natural language, no robot-like feeling</div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Learns about you and meets you at the right level</div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Understands context</div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Answers deep questions intelligently</div>
            </div>
          </div>
          <Button class="w-full"> Get Started </Button>
          <p class="text-center text-gray-500 text-sm mt-4">30-day money-back guarantee</p>
        </div>
      </div>

      <!-- Yearly Plan -->
      <div class="max-w-sm rounded-2xl border overflow-hidden bg-white relative">
        <div class="absolute top-0 left-0 bg-blue-500 text-white px-3 py-1 text-sm font-semibold rounded-br">
          Early Bird
        </div>
        <div class="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 text-sm font-semibold">Save 20%</div>
        <div class="px-6 py-8">
          <h2 class="text-center text-3xl font-semibold mb-4 serif">Yearly</h2>
          <p class="text-center text-gray-600 mb-6">Get all the benefits and save with our yearly plan.</p>
          <div class="text-center mb-6">
            <div class="mb-1">
              <span class="text-gray-500 line-through">1,440 kr</span>
              <span class="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded ml-2"
                >Expires 31 Dec 2024</span
              >
            </div>
            <span class="text-4xl font-bold">960 kr</span>
            <span class="text-gray-600"> per year</span>
          </div>
          <div class="mb-6 space-y-2">
            <!-- Features List -->
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Speak with AI in English or Danish</div>
            </div>
            <!-- Repeat for other features -->
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Get answers in English or Danish</div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Natural language, no robot-like feeling</div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Learns about you and meets you at the right level</div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Understands context</div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <Icon class="text-green-500 w-6 h-6" name="material-symbols:check" />
              <div class="flex-1">Answers deep questions intelligently</div>
            </div>
          </div>
          <Button class="w-full"> Get Started </Button>
          <p class="text-center text-gray-500 text-sm mt-4">30-day money-back guarantee</p>
        </div>
      </div>
    </div>
  </div>
</template>
