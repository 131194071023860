<template>
  <section class="py-16 text-center" id="contact">
    <h2 class="text-3xl serif">Want to talk, or ask some question?</h2>
    <p class="mt-4 text-lg text-gray-500">
      Reach out at <a href="mailto:hey@betterdanish.dk" class="underline underline-offset-2">hey@betterdanish.dk</a> and
      let's chat!
    </p>
    <a href="mailto:hey@betterdanish.dk">
      <Button class="mt-8" variant="default" size="lg">Reach out now</Button>
    </a>
  </section>
</template>
