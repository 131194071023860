import Contact from './Contact.vue'
<template>
  <LandingHero />

  <LandingFeatures />

  <div class="bg-white pb-10">
    <div class="bg-gray-100 py-16 sm:py-24 lg:py-12 rounded-2xl max-w-4xl m-auto flex px-12 gap-4 border">
      <img src="/images/freja2.png" alt="Illustration" class="mx-auto w-56 object-contain" />
      <div class="flex flex-col justify-center ml-8">
        <h2 class="text-4xl text-gray-800 serif mb-2">Meet Freja</h2>
        <p class="mt-4 text-gray-600">
          Freja is the AI assistant that speaks danish with you in real-time, giving you instant feedback on your spoken
          or written danish, on topics such as pronunciation, grammar, vocabulary, and much more.
        </p>

        <p class="mt-4 text-gray-600">
          There's no reason to be shy, or embarassed. Freja doesn't judge, and is always eager to talk. She's the
          perfect language partner.
        </p>
      </div>
    </div>
  </div>

  <LandingPricing />

  <LandingContact />
</template>
