<script setup lang="ts">
const features = [
  {
    title: "Interesting. Not boring",
    description: "Learn Danish in a fun and engaging way, with lessons that keep you motivated.",
    icon: "material-symbols:emoji-events",
  },
  {
    title: "Bilingual Assistance",
    description: "Communicate in English or Danish as you learn, making the transition smoother.",
    icon: "material-symbols:translate",
  },
  {
    title: "Natural Conversations",
    description: "Practice speaking and understanding Danish in a friendly, conversational manner.",
    icon: "material-symbols:chat-bubble-outline",
  },
  {
    title: "Personalized Learning",
    description: "Get customized lessons that adapt to your level and learning pace.",
    icon: "material-symbols:school",
  },
  {
    title: "Contextual Understanding",
    description: "Learn how to use phrases and expressions in real-life situations.",
    icon: "material-symbols:lightbulb-outline",
  },
  {
    title: "Expert Guidance",
    description: "Receive clear explanations and answers to your questions about the Danish language.",
    icon: "material-symbols:help-outline",
  },
];
</script>
<template>
  <div class="py-16 bg-white" id="features">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Header -->
      <div class="lg:text-center">
        <h2 class="text-base text-red-600 font-semibold tracking-wide uppercase">What is betterdanish?</h2>
        <p class="mt-4 text-4xl leading-8 text-gray-900 sm:text-4xl mx-auto serif max-w-[600px]">
          Meet your teacher, guide, and companion in Learning Danish
        </p>
        <p class="mt-6 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Artificial Intelligence is being put to great use. BetterDanish is an amazing danish teacher that listens and
          speaks to you. And it's available anytime, anywhere, whenever you're ready.
        </p>
      </div>

      <!-- Features Grid -->
      <div class="mt-14">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <!-- Feature 1 -->
          <div class="relative" v-for="item in features">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                <Icon :name="item.icon" class="w-6 h-6" />
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                {{ item.title }}
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              {{ item.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>
