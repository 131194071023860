<template>
  <section class="bg-gray-50">
    <div class="mx-auto max-w-screen-xl px-4 py-8 md:py-32 flex flex-col md:flex-row lg:items-center gap-8">
      <div class="mx-auto md:max-w-xl text-left w-full md:w-[50%]">
        <h1 class="text-6xl serif">
          Learn Danish
          <div><span>by speaking </span><span class="text-red-700">Danish</span></div>
        </h1>

        <p class="mt-6 sm:text-xl/relaxed">
          Meet the AI danish teacher that you'll enjoy <b>speaking to</b>. Learn Danish, and ask all the stupid
          questions <b>with courage</b>.
        </p>

        <div class="mt-6 flex flex-wrap gap-2">
          <a href="/#features">
            <Button variant="ghost">Learn more</Button>
          </a>
          <a href="/app">
            <Button class="bg-red-700 hover:bg-red-600">Get started</Button>
          </a>
        </div>
      </div>

      <div class="md:w-[50%] w-full h-[300px] flex items-center justify-center">
        <video
          src="/public/talking1.mp4"
          muted
          autoplay
          loop
          class="object-cover rounded-2xl bg-red-200 h-full w-full md:max-w-[400px]"
        ></video>
      </div>
    </div>
  </section>
</template>
